import React, {useState, useEffect, useContext} from 'react'

const BaseContext = React.createContext({})

export const useBase = () => {
  return useContext(BaseContext)
}

export const BaseProvider = ({children}) => {

  const [scrollingOffset, setScrollingOffset] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", readyToScroll);
    return () => {
      window.removeEventListener("scroll", readyToScroll);
    }
  })

  const readyToScroll = () => {
    if (document.documentElement.scrollTop > 0) {
      setScrollingOffset(() => true)
    } else {
      setScrollingOffset(() => false)
    }
  }

  return (
    <BaseContext.Provider value={{
      scrollingOffset
    }} >
      { children }
    </BaseContext.Provider>
  )

  
}