import React, {useState, useContext} from 'react'

const MenuContext = React.createContext({})

export const useMenu = () => {
  return useContext(MenuContext)
}

export const MenuProvider = ({children}) => {
  const [menuState, setMenuState] = useState(false)
  const [darkMatterState, setDarkMatterState] = useState(false)

  const toggleMenu = () => {
    setMenuState(prev => !prev)
    setDarkMatterState(prev => !prev)

    setTimeout(() => {
      setDarkMatterState(prev => !prev)
    }, 1600)
  }

  return (
    <MenuContext.Provider value={{
      toggleMenu,
      darkMatterState,
      menuState
    }} >
      { children }
    </MenuContext.Provider>
  )

  
}