import React, { useState } from "react"

import { useBase } from "~s/Base/BaseContext"

import "./aside.scss"

const Aside = () => {
  const [phone, setPhone] = useState("")
  const [telegramNickname, setTelegramNickname] = useState("")
  const [email, setEmail] = useState("")
  const [showMobileSocials, setShowMobileSocials] = useState(false)
  const { scrollingOffset }: any = useBase()

  return (
    <aside className={`aside ${scrollingOffset ? 'scrolling' : ''}`}>
        <button type="button" className="aside__socials-button" onClick={ () => setShowMobileSocials ( () => !showMobileSocials ) }>
          <svg viewBox="-21 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M453.332 85.332c0 38.293-31.039 69.336-69.332 69.336s-69.332-31.043-69.332-69.336C314.668 47.043 345.707 16 384 16s69.332 31.043 69.332 69.332zm0 0"/><path d="M384 170.668c-47.063 0-85.332-38.273-85.332-85.336C298.668 38.273 336.938 0 384 0s85.332 38.273 85.332 85.332c0 47.063-38.27 85.336-85.332 85.336zM384 32c-29.418 0-53.332 23.938-53.332 53.332 0 29.398 23.914 53.336 53.332 53.336s53.332-23.938 53.332-53.336C437.332 55.938 413.418 32 384 32zM453.332 426.668C453.332 464.957 422.293 496 384 496s-69.332-31.043-69.332-69.332c0-38.293 31.039-69.336 69.332-69.336s69.332 31.043 69.332 69.336zm0 0"/><path d="M384 512c-47.063 0-85.332-38.273-85.332-85.332 0-47.063 38.27-85.336 85.332-85.336s85.332 38.273 85.332 85.336c0 47.059-38.27 85.332-85.332 85.332zm0-138.668c-29.418 0-53.332 23.938-53.332 53.336C330.668 456.063 354.582 480 384 480s53.332-23.938 53.332-53.332c0-29.398-23.914-53.336-53.332-53.336zM154.668 256c0 38.293-31.043 69.332-69.336 69.332C47.043 325.332 16 294.293 16 256s31.043-69.332 69.332-69.332c38.293 0 69.336 31.039 69.336 69.332zm0 0"/><path d="M85.332 341.332C38.273 341.332 0 303.062 0 256s38.273-85.332 85.332-85.332c47.063 0 85.336 38.27 85.336 85.332s-38.273 85.332-85.336 85.332zm0-138.664C55.914 202.668 32 226.602 32 256s23.914 53.332 53.332 53.332c29.422 0 53.336-23.934 53.336-53.332s-23.914-53.332-53.336-53.332zm0 0"/><path d="M135.703 245.762c-7.426 0-14.637-3.864-18.562-10.774-5.825-10.218-2.239-23.254 7.98-29.101l197.95-112.852c10.218-5.867 23.253-2.281 29.1 7.977 5.825 10.218 2.24 23.254-7.98 29.101L146.238 242.965a21.195 21.195 0 0 1-10.535 2.797zM333.633 421.762c-3.586 0-7.211-.899-10.54-2.797L125.142 306.113c-10.22-5.824-13.801-18.86-7.977-29.101 5.8-10.239 18.856-13.844 29.098-7.977l197.953 112.852c10.219 5.824 13.8 18.86 7.976 29.101-3.945 6.91-11.156 10.774-18.558 10.774zm0 0"/></svg>
        </button>
      <div className={`aside__socials ${ showMobileSocials ? 'visible' : '' }`}>
        <a
          href={`tg://resolve?domain=${telegramNickname}`}
          target="blank"
          className={`aside__social aside__social--telegram aside__social--light`}
          onMouseEnter={() => setTelegramNickname("satansoft")}
          onMouseLeave={() => setTelegramNickname("")}
          onTouchStart={() => setTelegramNickname("satansoft")}
          onTouchCancel={() => setTelegramNickname("")}
          title="Message via telegram"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.417 15.181l-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931L23.93 3.821l.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693L18.953 5.78c.595-.394 1.136-.176.691.218z" />
          </svg>
        </a>
        <a
          href={`https://web.whatsapp.com/send?phone=${phone}`}
          target="blank"
          className={`aside__social aside__social--whatsapp aside__social--light`}
          onMouseEnter={() => setPhone("380664228813")}
          onMouseLeave={() => setPhone("")}
          onTouchStart={() => setPhone("380664228813")}
          onTouchCancel={() => setPhone("")}
          title="Message via whatsapp"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.507 14.307l-.009.075c-2.199-1.096-2.429-1.242-2.713-.816-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z" />
            <path d="M20.52 3.449C12.831-3.984.106 1.407.101 11.893c0 2.096.549 4.14 1.595 5.945L0 24l6.335-1.652c7.905 4.27 17.661-1.4 17.665-10.449 0-3.176-1.24-6.165-3.495-8.411zm1.482 8.417c-.006 7.633-8.385 12.4-15.012 8.504l-.36-.214-3.75.975 1.005-3.645-.239-.375c-4.124-6.565.614-15.145 8.426-15.145a9.865 9.865 0 017.021 2.91 9.788 9.788 0 012.909 6.99z" />
          </svg>
        </a>
        {/* <a href="https://www.upwork.com/freelancers/~01f526a889c5e8d764" target="blank" className={`aside__social aside__social--upwork aside__social--${backgroundType}`} title="Hire on Upwork">
        <svg viewBox="0 0 180.9 128.3">
          <path d="M139.9,72.9c-8.3,0-16.1-3.5-23.2-9.3l1.7-8.1l0.1-0.3c1.5-8.6,6.4-23.1,21.4-23.1c11.2,0,20.4,9.1,20.4,20.4
            C160.3,63.8,151.1,72.9,139.9,72.9z M139.9,11.5c-19.2,0-34,12.4-40,32.9C90.7,30.6,83.7,14,79.6,0H59v53.6
            c0,10.6-8.6,19.2-19.2,19.2s-19.2-8.6-19.2-19.2V0H0v53.6c-0.1,22,17.8,40,39.8,40c22,0,39.8-18,39.8-40v-9
            c4,8.4,8.9,16.8,14.9,24.3l-12.6,59.4H103l9.1-43.1c8,5.1,17.2,8.4,27.8,8.4c22.6,0,41-18.5,41-41.1
            C180.9,29.9,162.5,11.5,139.9,11.5L139.9,11.5z"/>
        </svg>
      </a> */}
        <a
          href="https://www.linkedin.com/in/sergey-nazarov-184196138/"
          target="blank"
          className={`aside__social aside__social--linkedin aside__social--light`}
          title="Contact via Linkedin"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.994 24v-.001H24v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07V7.976H8.489v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243V24zM.396 7.977h4.976V24H.396zM2.882 0C1.291 0 0 1.291 0 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909A2.884 2.884 0 002.882 0z" />
          </svg>
        </a>
        <a
          href={`mailto:${email}`}
          className={`aside__social aside__social--email aside__social--light`}
          onMouseEnter={() => setEmail("scorpovi4@gmail.com")}
          onMouseLeave={() => setEmail("")}
          onTouchStart={() => setEmail("scorpovi4@gmail.com")}
          onTouchCancel={() => setEmail("")}
          title="Contact via E-mail"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g id="letter-main" fill="#f2f2f2">
              <path d="m484.973 122.808-32.685 328.209H59.712L33.379 129.16 256 253.802z" />
              <path d="M473.886 60.983 256 265.659 38.114 60.983H256z" />
            </g>
            <path
              d="M59.712 155.493v295.524H24.139C10.812 451.017 0 440.206 0 426.878V111.967l39 1.063 20.712 42.463z"
              id="letter-stripe"
            />
            <path
              d="M512 111.967v314.912c0 13.327-10.812 24.139-24.152 24.139h-35.56V155.493l19.692-46.525 40.02 2.999z"
              id="letter-stripe"
            />
            <path
              d="M512 85.122v26.845l-59.712 43.526L256 298.561 59.712 155.493 0 111.967V85.122c0-13.327 10.812-24.139 24.139-24.139h13.975L256 219.792 473.886 60.983h13.962c13.34 0 24.152 10.811 24.152 24.139z"
              id="letter-stripe"
            />
            <path id="letter-stripe" d="M59.712 155.493 0 146.235v-34.268z" />
          </svg>
        </a>
      </div>
    </aside>
  )
}

export default Aside
