import React, { useState } from "react"

import { useMenu } from "~s/Menu/MenuContext"
import { useBase } from "~s/Base/BaseContext"

import "./header.scss"

const Header = () => {
  const [activeBurgerButton, setActiveBurgerButton] = useState(false)
  const { toggleMenu }: any = useMenu()
  const { scrollingOffset }: any = useBase()


  const activeBurger = (): void => {
    setActiveBurgerButton(prev => !prev)
    toggleMenu()
  }

  return (
    <header className={`header ${scrollingOffset ? 'scrolling' : ''}`}>
      <div className="header__logo">
        <a href="/">SN</a>
      </div>
      <div className="header__burger">
        <button
          type="button"
          className={`burger ${activeBurgerButton ? "is-active" : ''}`}
          title="Toggle menu"
          onClick={activeBurger}
        >
          <span className="burger__bar burger__bar--hidden">Toggle menu</span>
          <span className="burger__bar burger__bar--1"></span>
          <span className="burger__bar burger__bar--2"></span>
          <span className="burger__bar burger__bar--3"></span>
        </button>
      </div>
    </header>
  )
}

export default Header
