import React from "react"

import "./footer.scss"

const Footer = () => {
    const date = new Date();
    return (
        <footer className="footer">
            <div className="footer__logo">© {date.getFullYear()} Sergey Nazarov</div>
        </footer>
    )
}

export default Footer
