import React, { ReactNode, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { BaseProvider } from "~s/Base/BaseContext"
import { MenuProvider } from "~s/Menu/MenuContext"
import Menu from '~c/partials/menu/menu'
import Header from "~c/partials/header/header"
import Footer from "~c/partials/footer/footer"
import Aside from "~c/partials/aside/aside"
import Photo from "~a/images/portrait.jpg"

import "./layout.scss"

interface Props {
  children: ReactNode
  meta?: {
    title?: string
    description?: string
    schema?: string
    canonical?: string
    robots?: []
    siteName?: string
    openGraph?: {
      type?: string
      image?: string
    }
  }
}

const Layout = ({ children, meta = {} }: Props) => {
  const metaData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          job_title
          siteUrl
        }
      }
    }
  `)

  const { title, description, author, job_title, siteUrl } =
    metaData.site.siteMetadata

  const [menuState, setmenuState] = useState(false)
    
  return (
    <MenuProvider>
      <BaseProvider>
        <>
          <Helmet defer={false}>
            <html className={menuState ? "no-scroll" : ""} />
            <title>{meta.siteName ? `${ meta.title } | ${ meta.siteName }` : `${ meta.title } | ${ title }`}</title>
            <script type="application/ld+json">
              {`
                  {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "${meta.title ?? title}",
                    "description": "${meta.description ?? description}",
                    "author": {
                      "@type": "Person",
                      "name": "${author}",
                      "image": "${siteUrl + Photo}",
                      "jobTitle": "${job_title}",
                      "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Ukraine, Kherson",
                        "postalCode": "73002"
                      }
                    }
                  }

                  ${meta.schema ? meta.schema : ""}
                `}
            </script>
            <meta name="description" content={meta.description ?? description} />
            <link rel="canonical" href={siteUrl + meta.canonical} />
            <meta
              name="robots"
              content={meta.robots ? meta.robots.join(", ") : "index, follow"}
            />

            <meta
              property="og:type"
              content={meta.openGraph?.type ?? "website"}
            />
            <meta property="og:title" content={ meta.title ?? title } />
            <meta
              property="og:description"
              content={ meta.description ?? description }
            />
            <meta
              property="og:image"
              content={
                meta.openGraph?.image
                  ? siteUrl + meta.openGraph?.image
                  : siteUrl + Photo
              }
            />
            <meta property="og:url" content={ siteUrl + meta.canonical } />
            <meta property="og:site_name" content={ meta.siteName ?? title } />
          </Helmet>
          <Header />
          <div className="view-page">
            <main>
              {children}
              <Aside />
            </main>
            <Footer />
          </div>
          <Menu parentMenuState={setmenuState} />
        </>
      </BaseProvider>
    </MenuProvider>
  )
}

export default Layout
