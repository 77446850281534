import React, { useEffect, useRef } from "react"
import * as THREE from "three"

import { isBrowser } from "~c/utils/base"
import { useMenu } from "~s/Menu/MenuContext"

import "./fog.scss"

export default React.memo(() => {
  let camera: any,
    scene: any,
    renderer: any,
    mesh: any,
    clock: any,
    cubeSineDriver: any,
    smokeParticles: any

  const fogContainer = useRef<HTMLDivElement>(null)

  const { menuState }: any = useMenu()

  useEffect(() => {
    if (isBrowser) {
      if (menuState) {
        init()
        animate()
      } else {
        setTimeout(() => {
          if (fogContainer.current !== null) {
            renderer = null
            scene = null
            camera = null
            fogContainer.current.innerHTML = ''
          }
        }, 1000);
      }
    }
  },[menuState])

  const init = () => {
    clock = new THREE.Clock()

    renderer = new THREE.WebGLRenderer()

    renderer.setSize(window.innerWidth, window.innerHeight)

    scene = new THREE.Scene()

    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      10000
    )
    camera.position.z = 1000
    scene.add(camera)

    const geometry = new THREE.BoxGeometry(200, 200, 200)
    const material = new THREE.MeshLambertMaterial({
      color: 0xaa9999,
      wireframe: false,
    })
    mesh = new THREE.Mesh(geometry, material)
    //scene.add( mesh );
    cubeSineDriver = 0

    THREE.ImageUtils.crossOrigin = "" //Need this to pull in crossdomain images from AWS

    const light = new THREE.DirectionalLight(0xffffff, 0.5)
    light.position.set(-1, 0, 1)
    scene.add(light)

    const smokeTexture = new THREE.TextureLoader().load(
      "https://s3-us-west-2.amazonaws.com/s.cdpn.io/95637/Smoke-Element.png"
    )
    const smokeMaterial = new THREE.MeshLambertMaterial({
      color: 0x00dddd,
      map: smokeTexture,
      transparent: true,
    })
    const smokeGeo = new THREE.PlaneGeometry(300, 300)
    smokeParticles = []

    for (let p = 0; p < 150; p++) {
      var particle = new THREE.Mesh(smokeGeo, smokeMaterial)
      particle.position.set(
        Math.random() * 500 - 250,
        Math.random() * 500 - 250,
        Math.random() * 1000 - 100
      )
      particle.rotation.z = Math.random() * 360
      scene.add(particle)
      smokeParticles.push(particle)
    }

    if (fogContainer.current !== null) {
      fogContainer.current.appendChild(renderer.domElement)
    }
  }

  const animate = () => {
    const delta = clock.getDelta()
    requestAnimationFrame(animate)
    evolveSmoke(delta)
    render()
  }

  const evolveSmoke = (delta: any) => {
    let sp = smokeParticles.length
    while (sp--) {
      smokeParticles[sp].rotation.z += delta * 0.2
    }
  }

  const render = () => {
    mesh.rotation.x += 0.005
    mesh.rotation.y += 0.01
    cubeSineDriver += 0.01
    mesh.position.z = 100 + Math.sin(cubeSineDriver) * 500
    renderer.render(scene, camera)
  }

  return <div className="fog" ref={fogContainer}></div>
})
