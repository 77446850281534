import React, { useEffect } from 'react'
import { Link } from "gatsby"

import Fog from "~c/fog"
import { useMenu } from '~s/Menu/MenuContext'

import './menu.scss'

export default ({ parentMenuState }) => {

  const { darkMatterState, menuState }: any = useMenu()

  useEffect(() => {
    parentMenuState (() => menuState)
  }, [menuState])
  
  return (
    <>          
      <div
        className={`dark-matter ${darkMatterState ? "is-active" : ""}`}
      ></div>
      <nav className={`menu ${menuState ? "is-active" : ""}`} itemScope itemType="http://www.schema.org/SiteNavigationElement">
        <Fog></Fog>
        <ul>
          <li>
            <Link to="/" activeClassName="active" itemProp='url'>
              Home
            </Link>
          </li>
          <li>
            <Link to="/cases" activeClassName="active" itemProp='url'>
              Best cases
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active" itemProp='url'>
              About Me
            </Link>
          </li>
          <li>
            <Link to="/blog" activeClassName="active" itemProp='url'>
              Blog
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}